const cursor = document.querySelector(".bar-cursor");
const hamburger = document.querySelector(".bar-menu");
const navMenu = document.querySelector(".bar-nav");
const navMenuBackground = document.querySelector(".bar-nav-background");

hamburger.addEventListener("click", () => {
  hamburger.classList.toggle("active");
  navMenu.classList.toggle("active");
  navMenuBackground.classList.toggle("active");
});

window.addEventListener("mousemove", (e) => {
  cursor.style.left = e.pageX + "px";
  cursor.style.top = e.pageY + "px";
  cursor.setAttribute("data-fromTop", cursor.offsetTop - scrollY);
});

window.addEventListener("scroll", () => {
  const fromTop = cursor.getAttribute("data-fromTop");
  cursor.style.top = scrollY + parseInt(fromTop) + "px";
});

const ball = document.querySelector(".bar-cursor");

let mouseX = 0;
let mouseY = 0;
let ballX = 0;
let ballY = 0;
let speed = 0.2;

function animate() {
  let distX = mouseX - ballX;
  let distY = mouseY - ballY;
  ballX = ballX + distX * speed;
  ballY = ballY + distY * speed;
  ball.style.left = ballX + "px";
  ball.style.top = ballY + "px";
  requestAnimationFrame(animate);
}

animate();

document.addEventListener("mousemove", mouseMoveHandler);
function mouseMoveHandler(event) {
  let scrollLeft =
    window.pageXOffset !== undefined
      ? window.pageXOffset
      : (document.documentElement || document.body.parentNode || document.body)
          .scrollLeft;

  let scrollTop =
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;

  mouseX = event.pageX - scrollLeft;
  mouseY = event.pageY - scrollTop;
}

window.addEventListener("load", () => {
  const loader = document.querySelector(".loader");
  loader.classList.add("loader--hidden");
  loader.addEventListener("transitionend", () => {
    document.body.removeChild(loader);
  });
});

document.addEventListener("contextmenu", function (e) {
  e.preventDefault();
});
